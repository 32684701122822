.ant-upload.ant-upload-select-picture-card {
    border: 1px dashed #5F6B78;
    border-radius: 4px;
    background-color: #2C343A;
    text-align: center;
    cursor: pointer;
    -webkit-transition: border-color 0.3s ease;
    transition: border-color 0.3s ease;
    vertical-align: top;
    margin-right: 8px;
    margin-bottom: 8px;
    display: table;
}

.ant-upload-full .ant-upload-select-picture-card, .ant-upload-full .ant-upload-list-picture-card  {
    position: absolute;
    width: 100%;
    height: 100%;
}

.ant-upload-full .ant-upload-list-item {
    width: 100%;
    height: 100%;
}

.ant-upload-list-picture-card:hover .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
    opacity: 1;
}


.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #5F6B78;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #5F6B78;
}
