.ant-breadcrumb-separator {
    display: none!important;
}

.ant-breadcrumb-link a {
    position: relative;
    display: inline-block;
    color: #C5CED6;
    background: #2C343A;
    border-color: #2C343A;

    padding: 0 15px 0 32px;
    border-radius: 4px 0 0 4px;

    font-size: 11px;
    font-weight: 500;
    height: 35px;
    line-height: 33px;
    border: 1px solid transparent;
    border-left-width: 0;
    border-right-width: 0;
}

.ant-breadcrumb > span:first-child .ant-breadcrumb-link a {
    padding: 0 15px;
}


.ant-breadcrumb-link .arrow {
    display: inline-block;
    position: absolute;
    top: -1px;
    left: 100%;
    bottom: -1px;
    width: 16px;
    overflow: hidden;
    z-index: 1;
    margin-left: 0;
}

.ant-breadcrumb-link .arrow:before {
    content: "";
    display: inline-block;
    position: absolute;
    top: -3px;
    right: 0;
    bottom: -3px;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 17px solid #ECEFF5;
    border-left-color: #343C43;
}

.ant-breadcrumb-link .arrow:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: -3px;
    right: 2px;
    bottom: -3px;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 17px solid #ECEFF5;
    border-left-color: #2C343A;
}
