.ant-drawer-content {

}

.filter-handle {
    position: absolute;
    top: 240px;
    right: 300px;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    font-size: 16px;
    text-align: center;
    background: #2b85d9;
    border-radius: 4px 0 0 4px;
    cursor: pointer;
    pointer-events: auto;
}
