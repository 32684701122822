.header {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    top: 0;
    right: 0;
    height: 64px;
    background: #343C43;
    padding: 0 15px !important;
    transition: width .2s;
}

.headerLeft {
    flex: 1;
}

.headerRight {
    height: 100%;
    overflow: hidden;
}


.trigger {
    cursor: pointer;
}
