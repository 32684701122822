.action {
    cursor: pointer;
    padding: 0 12px;
    display: inline-block;
    transition: all 0.3s;
    height: 100%;
}

.action > i {
    vertical-align: middle;
    color: #000;
}

.action:hover {
    
}

.action .avatar {
    margin: calc((64px - 24px) / 2) 0;
    margin-right: 8px;
    color: #1890ff;
    background: hsla(0, 0%, 100%, .85);
    vertical-align: top;
}
