
.page-block {
    position: relative;
    padding: 32px;
    background: #343C43;
    border-radius: 12px;
}

.page-block-full {
    flex: 1;
    width: 100%;
    display: flex;
    flex-flow: column;
}

.page-block-header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
}

.page-block-header-start {
    flex: 1;
}

.page-block-header-end {
    margin-left: 30px;
}

.page-block-content {
    position: relative;
    flex: 1;
    width: 100%;
}


.page-header {

}

.page-header-title {
    flex: 1;
    min-width: 200px;
    font-size: 20px;
    font-weight: 400;
    color: #fff;
}

.page-header-actions {

}

.page-header-nav {
    position: relative;
    width: 100%;
    bottom: -16px;
}
