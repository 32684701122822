@import "layout/index.css";

@import "modal.css";
@import "menu.css";
@import "breadcrumbs.css";
@import "tab.css";
@import "table.css";
@import "pagination.css";
@import "form.css";
@import "popover.css";
@import "upload.css";

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    background: #1D2329;
}

img {
    max-width: 100%;
}


::-webkit-scrollbar {
    width: 6px;

}

::-webkit-scrollbar-track {
    box-shadow: none;
    background: #343C43;
}

::-webkit-scrollbar-thumb {
    background-color: #5C6369;
    border-radius: 2em;
    outline: none;
}


.logo {
    height: 32px;
    background: rgba(255,255,255,.2);
    margin: 16px;
}

.action-button {
    font-size: 22px;
}


.ant-tabs-bar {
    border-bottom: none;
    margin-bottom: 1px;
}


.ant-tag {
    padding: 0 5px;
    color: #9ab2c1;
    border: 1px solid #5F6B78;
    background: #21282E;
    display: flex;
    align-items: center;
}
.ant-tag span {
    flex: 1;
}

.ant-tag .anticon-close {
    position: relative;
    left: 2px;
    color: #5F6B78;
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #5F6B78;
}
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #5F6B78;
}

.ant-rate-star {
    margin-right: 4px;
}
