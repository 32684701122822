.menu {
  color: rgba(255, 255, 255, 0.65);
  background: #1D2329;
}


.groupName {
  padding-left: 24px;
  margin-bottom: 8px;
  line-height: 40px;
  height: 40px;

  color: #414952;
  font-weight: 500;
}

.divider {
  height: 25px;
}
