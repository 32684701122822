.ant-form-item {
    margin-bottom: 16px;
}

.ant-form-item2 {
    margin-bottom: 0;
}

.ant-form-item-label {
    line-height: 30px;
}

.ant-form-item-label label {
    font-size: 11px;
    line-height: 12px;
    color: #5F6B78;
    text-transform: uppercase;
}

.ant-form-item-required:before {
    position: relative;
    top: 1px;
    font-size: 11px;
}

.ant-form-explain, .ant-form-extra {
    margin-top: 0;
    font-size: 11px;
}


.ant-btn {
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    color: #7D91A5;
    background: #3C454D;
    border-color: #3C454D;
}

.ant-btn:hover, .ant-btn:focus {
    color: #7D91A5;
    background: #4b575f;
    border-color: #4b575f;
}

.ant-btn-disabled,
.ant-btn.disabled,
.ant-btn[disabled],
.ant-btn-disabled:hover,
.ant-btn.disabled:hover,
.ant-btn[disabled]:hover, .ant-btn-disabled:focus, .ant-btn.disabled:focus, .ant-btn[disabled]:focus, .ant-btn-disabled:active, .ant-btn.disabled:active, .ant-btn[disabled]:active, .ant-btn-disabled.active, .ant-btn.disabled.active, .ant-btn[disabled].active {
    color: #5f6f82;
    background: #3C454D;
    border-color: #3C454D;
    text-shadow: none;
    box-shadow: none;
}

.ant-btn-primary {
    color: #fff;
    background-color: #40a9ff;
    border-color: #40a9ff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
    color: #fff;
    background-color: #69c0ff;
    border-color: #69c0ff;
}

.ant-btn-danger {
    color: #fff;
    background-color: #D12F2F;
    border-color: #D12F2F;
}

.ant-btn-danger:hover,
.ant-btn-danger:focus {
    color: #fff;
    background-color: #dd3030;
    border-color: #dd3030;
}

.ant-select-dropdown-menu-item-selected,
.ant-select-dropdown-menu-item-selected:hover {
    background-color: #40a9ff;
    color: #fff;
}

.ant-select-dropdown-menu-item:hover,
.ant-select-dropdown-menu-item-active {
    background-color: #40a9ff;
}

.ant-select-selection {
    border: 1px solid #5F6B78;
    background-color: #21282E
}

.ant-select-arrow {
    color: #5F6B78;
}

.ant-select-disabled .ant-select-selection {
    border: 1px solid #5F6B78!important;
    background: #21282E;
    cursor: not-allowed;
    opacity: .9;
}

.ant-select-disabled {
    color: #fff;
}

.ant-select-selection--multiple .ant-select-selection__choice {
    color: #fff;
    background-color: #a6bace;
    border: 1px solid #5F6B78;
}

.ant-select-selection--multiple .ant-select-selection__choice__remove {
    color: #5F6B78;
}


.ant-input-group-addon {
    color: #5f6f82;
    text-align: center;
    background-color: #2f3841;
    border: 1px solid #3F484E;
}

.ant-input {
    font-size: 12px;
    color: #fff;
    background-color: #21282E;
    background-image: none;
    border: 1px solid #3F484E;
}

.ant-input-number {
    color: #fff;
    background-color: #21282E;
    border: 1px solid #3F484E;
}

.ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 36px;
}


.form-block {
    margin-bottom: 30px;
    padding: 24px;
    border-radius: 12px;
    background-color: #2C343A;
}

.ant-checkbox-group-item {
    display: block;
}

.ant-checkbox-inner {
    border: 1px solid #5F6B78;
    background-color: #21282E
}


.fieldset {
    position: relative;
    width: 100%;
    padding: 18px 32px;
    font-size: 14px;
    border-radius: 2px;
    border: 1px solid #5F6B78;
    transition: background-color .4s;
}

.fieldset-title {
    position: absolute;
    top: -14px;
    margin-left: -8px;
    padding: 1px 8px;
    color: #5F6B78;
    background: #2B343A;
    border-radius: 2px 2px 0 0;
    transition: background-color .4s;
}

.form-error {
    margin-bottom: 15px;
    padding: 8px 10px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    /*background: linear-gradient(30deg, rgb(240, 103, 168) 0%, rgb(247, 149, 80) 100%);*/
    background: linear-gradient(30deg, #FA3C71 0%, #F7642F 100%);
}
