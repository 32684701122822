.app-layout {
    padding-left: 200px;
    padding-right: 10px;
    min-height: 100vh;
    background: #1D2329;
}

.app-layout-sider {
    height: 100vh;
    position: fixed;
    left: 0;
    background: #1D2329;
    padding: 10px;
}

.app-layout-content {
    position: relative;
    display: flex;
    flex-flow: column;
}
