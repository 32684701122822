.container {

}

.label {
    margin-bottom: 10px;
    font-size: 11px;
    font-weight: 500;
    color: #5F6B78;
    text-transform: uppercase;
}

.photos {

}

.mainPhoto {
    width: 100%;
}

.otherPhotos {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -16px;
}

.photo {
    width: 50%;
    padding: 16px;
}
