.row {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #424a57;
}

.label {
  font-size: 11px;
  font-weight: 500;
  color: #5F6B78;
  text-transform: uppercase;
}