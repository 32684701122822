
.ant-modal-header {
    border-bottom: 1px solid #5F6B78;
}

.ant-modal-footer {
    border-top: 1px solid #5F6B78;
}

.ant-modal-title {
    color: #fff;
}
