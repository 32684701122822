.ant-popover-inner {
    background-color: #2e363d;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
    border-top-color: #2e363d;
    border-left-color: #2e363d;
}
