.status {
    display: inline-block;
    padding: 5px 8px;
    font-size: 12px;
    font-weight: 300;
    line-height: 1;
    border: 1px solid #eee;
    border-radius: 2em;
}

.status.s0 {
    color: #65CAFC;
    fill: #65CAFC;
    border-color: #65CAFC;
    background: rgba(101, 202, 252, 0.15);
}

.status.s1 {
    color: #ff9049;
    fill: #ff9049;
    border-color: #ff9049;
    background: rgba(255, 144, 73, 0.15);
}

.status.s3 {
    color: #32b373;
    fill: #32b373;
    border-color: #7ab3a1;
    background: rgba(50, 179, 115, 0.15);
}

.status.s4 {
    color: #bda0b9;
    fill: #bda0b9;
    border-color: #DFC0DB;
    background: rgba(223, 192, 219, 0.15);
}

.status.s5 {
    color: #FF3B2F;
    fill: #FF3B2F;
    border-color: #FF3B2F;
    background: rgba(255, 59, 47, 0.15);
}
