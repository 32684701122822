.main {
    position: relative;
    display: block;
    margin-bottom: 32px;
}

.main.full {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
}

.uploadIcon {
    font-size: 32px;
    color: #5F6B78;
}

.uploadText {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.25;
    color: #5F6B78;
}

.uploadImg {

}
